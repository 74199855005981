<template>
	<el-scrollbar class="uu-box">
		<div class="warp">
			<div class="return-tool"><span @click="$router.go(-1)"><i class="el-icon-arrow-left"> {{$t('seller.actions.back')}}</i></span></div>
			<el-tabs v-model="toolName" type="card">
				<el-tab-pane :label="$t('seller.logistics.paneTitle1')" name="feetemplate">
					<logisTemplate></logisTemplate>
				</el-tab-pane>
				<el-tab-pane :label="$t('seller.logistics.paneTitle2')" name="address">
					<logisAddress></logisAddress>
				</el-tab-pane>
				<el-tab-pane :label="$t('seller.logistics.paneTitle3')" name="waybill">
					<logisWaybill></logisWaybill>
				</el-tab-pane>
			</el-tabs>
		</div>
	</el-scrollbar>
</template>

<script>
	import logisTemplate from '@/views/seller/logistics/logisTemplate.vue'; //运费模板
	import logisAddress from '@/views/seller/logistics/logisAddress.vue'; //地址库
	import logisWaybill from '@/views/seller/logistics/logisWaybill.vue'; //运单模板
	export default {
		components: {
			logisTemplate,
			logisAddress,
			logisWaybill
		},
		data() {
			return {
				toolName: 'feetemplate',
			}
		},
		created () {
			if(this.$route.params.type) {
				this.toolName = this.$route.params.type
			}
		}
	}
</script>

<style lang="scss" scoped>
	.warp {
		padding: 18px 24px;
		.title {
			font-size: 16px;
		}

		.tool-list {
			display: flex;

			.tool-part {
				width: 275px;
				height: 72px;
				display: flex;
				padding: 20px 10px 15px;
				margin: 15px 20px 0 0;
				font-size: 14px;
				border: 1px solid #e6e9ed;
				border-radius: 9px;
				cursor: pointer;

				.icon {
					width: 42px;
					height: 42px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 15px;
					background: $--seller-primary;
					margin-right: 20px;

					img {
						width: 24px;
						height: 24px;
					}
				}

				.text {
					padding-top: 3px;
				}
			}
		}

		.return-tool {
			padding: 12px 0;
			font-size: 14px;
			margin-bottom: 15px;

			span {
				cursor: pointer;
			}
		}

		.add-part {
			display: flex;
			justify-content: right;
			padding: 15px 20px;
			border-left: 1px solid #EBEEF5;
			border-right: 1px solid #EBEEF5;
			font-size: 14px;

			span {
				cursor: pointer;
			}
		}

		.tips {
			text-align: center;
			padding: 12px 0;
		}

		.address {
			::v-deep .el-form-item__content {
				display: flex;

				::v-deep .el-select {
					margin-right: 20px;
				}
			}
		}

		.phone-number {
			::v-deep .el-form-item__content {
				display: flex;
			}

			.interval {
				margin: 0 10px;
			}
		}

		.tool-default {
			color: $--seller-primary;
			cursor: pointer;
		}

		::v-deep .el-tabs__header {
			margin-bottom: 0;
		}

		::v-deep .el-table--border {
			// border-bottom: 1px solid #EBEEF5;
		}

		::v-deep .el-table {
			margin-bottom: 12px;
		}

		::v-deep .el-dialog__body {
			overflow-y: auto;
			padding: 12px 40px;
			height: auto;

			.title {
				padding-bottom: 20px;
			}
		}
	}
</style>
