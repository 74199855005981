<template>
	<div class="logis-template">
		<div class="add-part"><span @click="handleFeetemplateAddShow"><i class="el-icon-document-add"></i> {{$t('seller.actions.newFreightTpl')}}</span>
		</div>
		<el-dialog :title="dialogType === 'edit'?$t('seller.dialogTitle.editFreightTpl'):$t('seller.dialogTitle.newFreightTpl')" width="900px" :visible.sync="dialogFormFeetemplate"
			:before-close="handleCloseFeetemplate">
			<el-scrollbar class="dialog-scrollbar">
				<el-form ref="feetemplateForm" :model="feetemplateForm" :rules="feetemplateRules" label-width="120px"
					size="small">
					<el-form-item :label="$t('seller.formItem.templateName')+':'" prop="template_name">
						<el-input v-model="feetemplateForm.template_name" maxlength="25" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.shippingSite')+':'" prop="region_id1">
						<ul class="address-list">
							<li v-for="(item,index) in addressList" :key="index">
								<el-select v-model="regionIds[index]" clearable @change="handleAddressChange($event,index)">
									<el-option v-for="address in item" :key="address.region_id" :label="address.region_name"
										:value="address.region_id"></el-option>
								</el-select>
							</li>
						</ul>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.shippingTime')+':'" prop="delivery_time">
						<el-select v-model="feetemplateForm.delivery_time" clearable>
							<el-option v-for="item in feetemplateTime" :key="item" :label="getDeliveryTime(item)" :value="item">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.isFree')+':'" prop="is_free">
						<el-radio-group v-model="feetemplateForm.is_free">
							<el-radio v-for="(item,index) in freeOption" :key="index" :label="item.value">{{item.label}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.pricingMode')+':'" prop="valuation_way">
						<el-radio-group v-model="feetemplateForm.valuation_way" @change="handleValuationWayChange">
							<el-radio v-for="(item,index) in pricingOption" :key="index" :label="item.value">{{item.label}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.shippingMode')+':'" prop="transport_way">
						<div>{{$t('seller.logistics.freightTips1')}}</div>
						<template v-for="obj in Object.keys(transportWay)">
							<div :key="obj">
								<div>
									<el-checkbox v-model="transportWay[obj].is_select" :true-label="1" :false-label="0">{{ obj | logisticsFilter($t('seller.tableHead.logisticsFilter'))}}</el-checkbox>
								</div>
								<div class="tools-ship" v-if="transportWay[obj].is_select==1 && parseInt(feetemplateForm.is_free)!==1">
									<div class="tools-ship-head">
										<div class="item">
											<span>{{$t('seller.logistics.freightText1')}}</span>
											<span>
												<el-input v-model="transportWay[obj].start_value"
													oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
											</span>
											<span>{{shipText.unit}}{{$t('seller.logistics.freightText2-1')}}</span>
											<span>
												<el-input v-model="transportWay[obj].start_fee"
													oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
											</span>
											<span>{{$t('seller.logistics.freightText2-2')}}</span>
											<span>
												<el-input v-model="transportWay[obj].add_value"
													oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
											</span>
											<span>{{shipText.unit}}{{$t('seller.logistics.freightText2-3')}}</span>
											<span>
												<el-input v-model="transportWay[obj].add_fee"
													oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
											</span>
											<span>{{$t('seller.logistics.freightText2-4')}}</span>
										</div>
										<div class="item">
											<span>
												<el-checkbox v-model="transportWay[obj].is_free" true-label="1"
													false-label="0">{{$t('seller.checkbox.setPackage')}}</el-checkbox>
											</span>
											<span>
												<el-select v-model="transportWay[obj].free_type"
													:disabled="transportWay[obj].is_free==1?false:true" @change="handleFreeTypeChange($event,obj)">
													<el-option :label="shipText.name" :value="1"></el-option>
													<el-option :label="$t('seller.logistics.amount')" :value="2"></el-option>
													<el-option :label="shipText.name+'+'+$t('seller.logistics.amount')" :value="3"></el-option>
												</el-select>
											</span>
											<template v-if="transportWay[obj].free_type==1">
												<span>{{$t('seller.logistics.freightText3-1')}}</span>
												<el-input v-model="transportWay[obj].free_value"
													:disabled="transportWay[obj].is_free==1?false:true"
													oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
													<span>{{shipText.unit}}{{$t('seller.logistics.freightText3-2')}}</span>
											</template>
											<template v-else-if="transportWay[obj].free_type==2">
												<span>{{$t('seller.logistics.freightText4-1')}}</span>
												<el-input v-model="transportWay[obj].free_price"
													:disabled="transportWay[obj].is_free==1?false:true"
													oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
												<span>{{$t('seller.logistics.freightText4-2')}}</span>
											</template>
											<template v-else-if="transportWay[obj].free_type==3">
												<span>{{$t('seller.logistics.freightText5-1')}}</span>
												<el-input v-model="transportWay[obj].free_value"
													:disabled="transportWay[obj].is_free==1?false:true"
													oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
												<span>{{shipText.unit}}{{$t('seller.logistics.freightText5-2')}}</span>
												<el-input v-model="transportWay[obj].free_price"
													:disabled="transportWay[obj].is_free==1?false:true"
													oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
												<span>{{$t('seller.logistics.freightText5-3')}}</span>
											</template>
										</div>
									</div>
									<div class="tools-ship-list" v-if="transportWay[obj].transport_list && transportWay[obj].transport_list.length>0">
										<el-table :data="transportWay[obj].transport_list">
											<el-table-column :label="$t('seller.tableHead.shipTo')">
												<template slot-scope="scope">
													<el-select v-model="scope.row.area_text">
														<el-option v-for="(item,index) in areaOption" :key="index" :label="item" :value="item"></el-option>
													</el-select>
												</template>
											</el-table-column>
											<el-table-column :label="$t('seller.tableHead.initial')+shipText.name+'('+shipText.unit+')'">
												<template slot-scope="scope">
													<el-input v-model="scope.row.start_value"
														oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
												</template>
											</el-table-column>
											<el-table-column :label="$t('seller.tableHead.initialFee')">
												<template slot-scope="scope">
													<el-input v-model="scope.row.start_fee"
														oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
												</template>
											</el-table-column>
											<el-table-column :label="$t('seller.tableHead.renewal')+shipText.name+'('+shipText.unit+')'">
												<template slot-scope="scope">
													<el-input v-model="scope.row.add_value"
														oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
												</template>
											</el-table-column>
											<el-table-column :label="$t('seller.tableHead.renewalFee')">
												<template slot-scope="scope">
													<el-input v-model="scope.row.add_fee"
														oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
												</template>
											</el-table-column>
											<el-table-column :label="$t('seller.tableHead.handle')">
												<template slot-scope="scope">
													<a @click="handleTransportDel(obj,scope.$index)">{{$t('seller.actions.delete')}}</a>
												</template>
											</el-table-column>
										</el-table>
									</div>
									<div><a @click="handleTransportAdd(obj)">{{$t('seller.actions.setFreight')}}</a></div>
								</div>
							</div>
						</template>
					</el-form-item>
				</el-form>
			</el-scrollbar>
			<div slot="footer">
				<el-button type="primary" @click="handleTeetemplateSubmit">{{$t('seller.actions.saveClose')}}</el-button>
				<el-button @click="handleCloseFeetemplate">{{$t('seller.actions.cancel')}}</el-button>
			</div>
		</el-dialog>
		<div class="logis-template-list" v-for="item in feetemplateList" :key="item.template_id">
			<div class="item-head">
				<span>{{item.template_name}}</span>
				<div class="head-bar">
					<div class="time">{{$t('seller.logistics.freightLastTime')+':'}}{{item.update_time | parseTime('{y}-{m}-{d} {h}:{i}')}}</div>
					<div class="action">
						<span @click="handleFeetemplateEditShow(item)">{{$t('seller.actions.edit')}}</span>
						<span @click="handleFeetemplateDel(item.template_id)">{{$t('seller.actions.delete')}}</span>
					</div>
				</div>
			</div>
			<table class="item-main">
				<thead>
					<tr>
						<th width="16%">{{$t('seller.tableHead.shippingMode')}}</th>
						<th>{{$t('seller.tableHead.shipTo')}}</th>
						<th width="16%">{{ item.valuation_way | initialFilter($t('seller.logistics.initialFilter')) }}</th>
						<th width="16%">{{ $t('seller.tableHead.freight') }}</th>
						<th width="16%">{{ item.valuation_way | renewalFilter($t('seller.logistics.renewalFilter')) }}</th>
						<th width="16%">{{ $t('seller.tableHead.freight') }}</th>
					</tr>
				</thead>
				<tbody v-if="Object.keys(item.transport_way).length>0">
					<template v-for="obj in Object.keys(item.transport_way)">
						<template v-if="item.transport_way[obj].is_select==1">
							<tr :key="obj">
								<td>{{ obj | logisticsFilter($t('seller.tableHead.logisticsFilter')) }}</td>
								<td>{{ $t('seller.logistics.china') }}</td>
								<td>{{getText(item.transport_way[obj].start_value,1)}}</td>
								<td>{{getText(item.transport_way[obj].start_fee,2)}}</td>
								<td>{{getText(item.transport_way[obj].add_value,1)}}</td>
								<td>{{getText(item.transport_way[obj].add_fee,2)}}</td>
							</tr>
							<template v-if="item.transport_way[obj].transport_list.length>0">
								<tr v-for="(child,cindex) in item.transport_way[obj].transport_list" :key="cindex">
									<td>{{ obj | logisticsFilter($t('seller.tableHead.logisticsFilter')) }}</td>
									<td>{{child.area_text}}</td>
									<td>{{getText(child.start_value,1)}}</td>
									<td>{{getText(child.start_fee,2)}}</td>
									<td>{{getText(child.add_value,1)}}</td>
									<td>{{getText(child.add_fee,2)}}</td>
								</tr>
							</template>
						</template>
					</template>
				</tbody>
			</table>
		</div>
		<div style="text-align: center;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next" :prev-text="$t('seller.pagination.prevText')"
				:next-text="$t('seller.pagination.nextText')" :total="listTotal">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { freightTplList, freightTime, createFreightTpl, updateFreightTpl, deleteFreightTpl } from '@/api/seller/logistics';
	
	const defaultForm = {
		template_id: '',
		template_name: '',
		region_id1: 0,
		region_id2: 0,
		region_id3: 0,
		region_id4: 0,
		delivery_time: '',
		valuation_way: '1',
		transport_way: '',
		is_free: '0'
	}
	const defaultTemplate = {
		express: {
			is_select: 0,
			start_value: '1',
			start_fee: '',
			add_value: '1',
			add_fee: '',
			is_free: 0,
			free_Type: 1,
			free_value: '',
			transport_list: []
		},
		ems: {
			is_select: 0,
			start_value: '1',
			start_fee: '',
			add_value: '1',
			add_fee: '',
			is_free: 0,
			free_Type: 1,
			free_value: '',
			transport_list: []
		},
		surface: {
			is_select: 0,
			start_value: '1',
			start_fee: '',
			add_value: '1',
			add_fee: '',
			is_free: 0,
			free_Type: 1,
			free_value: '',
			transport_list: []
		}
	}
	
	export default {
		name: 'logisTemplate',
		filters: {
			initialFilter(type,option) {
				return option[type - 1]
			},
			renewalFilter(type,option) {
				return option[type - 1]
			},
			logisticsFilter(type,option) {
				return option[type]
			}
		},
		data() {
			let self = this;
			var validateRegion = (rule, value, callback) => {
				if (parseInt(value) > 0) {
					callback();
				} else {
					callback(new Error(self.$t('seller.validator.required')));
				}
			}
			var validateTransport = (rule, value, callback) => {
				let data = value && typeof(value) == 'string' ? JSON.parse(value) : self.transportWay;
				let flag = 0;
				for (let i in data) {
					if (self.feetemplateForm.is_free == 1) {
						if (data[i].is_select == 1) {
							flag = 1;
						}
					} else {
						let is_list = 1;
						if (data[i].transport_list.length > 0) {
							data[i].transport_list.forEach(item => {
								if (item.area_text && parseInt(item.start_value) > 0 && parseFloat(item
										.start_fee) >= 0 && parseInt(item.add_value) > 0 && parseFloat(item
										.add_fee) >= 0) {
									is_list = 1;
								} else {
									is_list = 0;
								}
							})
						}
						if (data[i].is_select == 1 && is_list == 1 && parseInt(data[i].start_value) > 0 &&
							parseFloat(data[i].start_fee) >= 0 && parseInt(data[i].add_value) > 0 && parseFloat(
								data[i].add_fee) >= 0) {
							flag = 1;
						}
					}
				}
				if (flag == 1) {
					callback();
				} else {
					callback(new Error(self.$t('seller.validator.shippingMode')));
				}
			}
			return {
				addressList: [],
				feetemplateList: [],
				pagesize: 10,
				pagecurr: 1,
				listTotal: 0,
				feetemplateTime: [],
				dialogFormFeetemplate: false,
				dialogType: 'new',
				feetemplateForm: Object.assign({},defaultForm),
				freeOption: this.$t('seller.logistics.freeOption'),
				pricingOption: this.$t('seller.logistics.pricingOption'),
				areaOption: this.$t('seller.logistics.areaOption'),
				regionIds: [],
				transportWay: Object.assign({},defaultTemplate),
				feetemplateRules: {
					template_name: [{
						required: true,
						message: this.$t('seller.validator.required'),
						trigger: 'blur'
					}],
					region_id1: [{
						required: true,
						validator: validateRegion,
						trigger: 'blur'
					}],
					is_free: [{
						required: true,
						message: this.$t('seller.validator.required'),
						trigger: 'change'
					}],
					valuation_way: [{
						required: true,
						message: this.$t('seller.validator.required'),
						trigger: 'change'
					}],
					transport_way: [{
						validator: validateTransport,
						trigger: 'blur'
					}],
				},
				express: {
					startStandard: '',
					startFee: '',
					addStandard: '',
					addFee: '',
					freeCondition: false,
					freeType: 1,
					freeStandard: '',
				},
				shipText: {}
			}
		},
		created() {
			this.getAddressList();
			this.getFeetemplateList();
			this.getFeetemplateTime();
		},
		methods: {
			getAddressList(pid = 0, index = -1) {
				fetchRegion({parent_id: pid}).then(response => {
					const { data } = response;
					this.addressList.splice(index);
					if (data.list && data.list.length > 0) {
						this.addressList.push(data.list);
					}
				})
			},
			getFeetemplateList() {
				let param = {
					page_index: (this.pagecurr - 1) * this.pagesize,
					page_size: this.pagesize
				}
				freightTplList(param).then(response => {
					this.feetemplateList = response.data.list;
					this.feetemplateList.forEach(item => {
						item.transport_way = item.transport_way?JSON.parse(item.transport_way):[];
					})
					this.listTotal = parseInt(response.data.total)
				})
			},
			getFeetemplateTime() {
				freightTime({uuid: this.$store.getters.uuid}).then(response => {
					this.feetemplateTime = response.data.list
				})
			},
			getRegionName(id) {
				let name = '';
				if(this.addressList[0]){
					let list = JSON.parse(JSON.stringify(this.addressList[0]));
					list.forEach(item => {
						if (item.region_id == id) {
							name = item.region_name;
						}
					})
				}
				return name;
			},
			handleSizeChange(val) {
				this.pagesize = val;
			},
			handleCurrentChange(val) {
				this.pagecurr = val;
				this.getFeetemplateList();
			},
			handleFeetemplateAddShow() {
				this.dialogType = 'new';
				this.regionIds = [];
				this.addressList.splice(1);
				this.transportWay = Object.assign({},defaultTemplate)
				this.feetemplateForm = Object.assign({},defaultForm)
				if(this.feetemplateForm.template_id>0){
					this.$delete(this.feetemplateForm,'template_id');
				}
				this.handleValuationWayChange(this.feetemplateForm.valuation_way);
				this.dialogFormFeetemplate = true;
			},
			handleAddressChange(val, index) {
				if(val){
					this.regionIds.splice(index + 1);
					this.getAddressList(val, index + 1);
				}else {
					this.regionIds.splice(index);
					this.addressList.splice(index+1);
				}
			},
			getDeliveryTime(time){
				if (time < 24) {
					return time + this.$t('seller.unit.hours')
				}else {
					return time / 24 + this.$t('seller.unit.days')
				}
			},
			handleTransportAdd(obj) {
				let item = {
					area_text: '',
					start_value: '',
					start_fee: '',
					add_value: '',
					add_fee: ''
				}
				this.transportWay[obj].transport_list.push(item);
			},
			handleTransportDel(obj, index) {
				this.transportWay[obj].transport_list.splice(index, 1);
			},
			handleFeetemplateEditShow(value) {
				let that = this;
				let data = JSON.parse(JSON.stringify(value))
				that.feetemplateForm = data;
				if(!data.delivery_time>0){
					that.feetemplateForm.delivery_time = '';
				}
				that.transportWay = Object.assign({}, Object.keys(data.transport_way).length > 0 ? data.transport_way : defaultTemplate);
				let promiseArr = [];
				let regionIds = [];
				that.addressList.splice(1);
				for(let i=1;i<5;i++){
					if(data['region_id' + i] > 0){
						regionIds.push(data['region_id' + i]);
						promiseArr.push(that.initRegionList(data['region_id' + i]));
					}
				}
				Promise.all(promiseArr).then(object=>{
					object.forEach(item=>{
						if(item.length>0){
							that.addressList.push(item);
						}
					})
				})
				that.regionIds = regionIds;
				that.handleValuationWayChange(data.valuation_way);
				that.dialogType = 'edit';
				that.dialogFormFeetemplate = true;
			},
			initRegionList(pid=45056){
				return new Promise((resolve,reject)=>{
					fetchRegion({parent_id: pid}).then(response => {
						resolve(response.data.list)
					}).catch(error => {
						reject(error)
					})
				})
			},
			handleCloseFeetemplate() {
				this.regionIds = [];
				this.addressList.splice(1);
				this.$refs.feetemplateForm.clearValidate();
				this.dialogFormFeetemplate = false;
			},
			handleTeetemplateSubmit() {
				let that = this;
				if(that.regionIds[0]==45056 && !that.regionIds[2]>0){
					that.$message.error(that.$t('seller.errorMsg.tplRegion'));
					return false;
				}
				let loading = that.$loading();
				that.feetemplateForm.region_id1 = that.regionIds[0] > 0 ? that.regionIds[0] : 0;
				that.feetemplateForm.region_id2 = that.regionIds[1] > 0 ? that.regionIds[1] : 0;
				that.feetemplateForm.region_id3 = that.regionIds[2] > 0 ? that.regionIds[2] : 0;
				that.feetemplateForm.region_id4 = that.regionIds[3] > 0 ? that.regionIds[3] : 0;
				let transportWay = JSON.parse(JSON.stringify(that.transportWay));
				for(let obj in transportWay) {
					if(transportWay[obj].is_select !== 1) {
						transportWay[obj] = defaultTemplate[obj]
					}
				}
				that.feetemplateForm.transport_way = JSON.stringify(transportWay);
				that.$refs.feetemplateForm.validate((valid, object) => {
					if (valid) {
						if(that.dialogType !== 'edit') {
							that.$delete(that.feetemplateForm,'template_id')
							createFreightTpl(that.feetemplateForm).then(() => {
								that.$message.success(that.$t('seller.successMsg.save'));
								that.getFeetemplateList();
								that.dialogFormFeetemplate = false;
								loading.close();
							}).catch(()=>{
								loading.close();
							})
						} else {
							updateFreightTpl(that.feetemplateForm).then(() => {
								that.$message.success(that.$t('seller.successMsg.save'));
								that.getFeetemplateList();
								that.dialogFormFeetemplate = false;
								loading.close();
							}).catch((err) => {
								loading.close();
							})
						}
					} else {
						loading.close();
						return false;
					}
				})
			},
			handleFeetemplateDel(id) {
				this.$confirm(this.$t('seller.confirm.deleteTplText'), this.$t('seller.confirm.title'), {
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					deleteFreightTpl({template_id: id}).then(() => {
						this.getFeetemplateList();
						this.$message.success(this.$t('seller.successMsg.delete'))
					})
				}).catch(() => {
					this.$message(this.$t('seller.cancelMsg.delete'));
				})
			},
			getText(val,type){
				let text = '';
				if(type==1){
					text = parseInt(val)>0?parseInt(val):1;
				}else {
					text = (Math.ceil(val*100)/100).toFixed(2);
				}
				return text;
			},
			handleValuationWayChange(val = 1) {
				const option = this.$t('seller.logistics.pricingJson')
				this.shipText = option[val - 1]
			},
			handleFreeTypeChange(val,type){
				// this.transportWay[type].free_type
				// console.log(val,type);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.logis-template {
		.add-part {
			display: flex;
			justify-content: right;
			padding: 15px 20px;
			font-size: 14px;
			border-left: 1px solid #EBEEF5;
			border-right: 1px solid #EBEEF5;

			span {
				cursor: pointer;
			}
		}

		.address-list {
			display: flex;

			li {
				flex-shrink: 0;
				width: 160px;
				margin-right: 10px;
			}

			li:last-child {
				margin-right: 0;
			}
		}

		.tools-ship {
			.tools-ship-head {
				border: 1px solid #eee;
				padding: 10px 5px;
				background-color: $--seller-thead-background-2;

				span {
					margin: 0 4px;
					display: inline-block;
				}

				.el-input {
					width: 80px;
				}

				.item {
					margin-top: 10px;

					.el-select {
						width: 120px;
					}
				}

				.item:first-child {
					margin-top: 0;
				}
			}
		}

		.logis-template-list {
			margin-bottom: 20px;

			.item-head {
				padding: 10px;
				background-color: $--seller-thead-background-2;
				border: 1px solid #DDDDDD;
				border-bottom: none;
				font-size: 14px;
				.head-bar {
					float: right;
					white-space: nowrap;
					.time {
						color: #999999;
						margin-right: 10px;
						display: inline-block;
						vertical-align: middle;
					}
					.action {
						display: inline-block;
						vertical-align: middle;
						white-space: nowrap;

						span {
							cursor: pointer;
							color: $--seller-primary;
							display: inline-block;
							padding-left: 10px;
							margin-left: 10px;
							border-left: 1px solid #ddd;
						}

						span:first-child {
							border-left: none;
						}
					}
				}
			}

			.item-main {
				width: 100%;

				th,
				td {
					padding: 10px;
					text-align: left;
					border: 1px solid #DDDDDD;
				}
			}
		}
		.dialog-scrollbar {
			height: 500px!important;
		}
	}
</style>
