<template>
	<div class="logis-waybill">
		<div class="add-part"><span @click="handleAddWaybill"><i class="el-icon-document-add"></i> {{$t('seller.actions.newTemplate')}}</span></div>
		<el-dialog
			:title="dialogType==='edit'?$t('seller.dialogTitle.editTemplate'):$t('seller.dialogTitle.newTemplate')"
			width="900px"
			:visible.sync="dialogVisibleWaybill"
			:before-close="handleCloseWaybill">
			<el-form ref="waybillForm" :model="waybillForm" :rules="waybillRules" label-width="120px">
				<el-form-item :label="$t('seller.formItem.templateName')" prop="template_name">
					<el-input v-model="waybillForm.template_name" maxlength="20"></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.deliveryCompany')" prop="logistics">
					<el-select v-model="waybillForm.logistics">
						<el-option
							v-for="item in logisList"
							:key="item.delivery_id"
							:label="item.name"
							:value="item.code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.selectPrint')" prop="checkitems">
					<el-checkbox-group v-model="waybillForm.checkitems">
						<el-checkbox v-for="item,index in printList" :key="index" :label="item.label">{{item.label}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleSubmitWaybill">{{$t('seller.actions.save')}}</el-button>
			</div>
		</el-dialog>
		<el-table :data="waybillList" border style="width: 100%;">
			<el-table-column :label="$t('seller.tableHead.templateName')" prop="template_name">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.deliveryCompany')" prop="">
				<template slot-scope="scope">
					{{getLogisName(scope.row.logistics)}}
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.isDefault')">
				<template slot-scope="scope">
					<span v-if="scope.row.is_default==1">{{$t('seller.tag.default')}}</span>
					<a class="action-link" href="javascript:void(0);" @click="handleSetDefalut(scope.row.template_id)" v-else>{{$t('seller.actions.setDefault')}}</a>
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.handle')">
				<template slot-scope="scope">
					<a class="action-link mr10" href="javascript:void(0);" @click="handleEditWaybill(scope.row.template_id)">{{$t('seller.actions.edit')}}</a>
					<a class="action-link" v-if="scope.row.is_default!==1" href="javascript:void(0);" @click="handleDeleteWaybill(scope.row.template_id)">{{$t('seller.actions.delete')}}</a>
					<a class="action-link disabled" v-else href="javascript:void(0);">{{$t('seller.actions.delete')}}</a>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align: center;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next" :prev-text="$t('seller.pagination.prevText')"
				:next-text="$t('seller.pagination.nextText')" :total="listTotal">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { getWaybillList, expressCompany, fetchWaybill, createWaybill, updateWaybill, defaultWaybill, deleteWaybill } from '@/api/seller/logistics'

	const defaultForm = {
		template_name: '',
		logistics: '',
		checkitems: ['订单编号','发件人姓名','发件人电话','发件人公司','发件人地址','发件人邮编','收件人姓名',
		'收件人电话','收件人地址','收件人邮编','代收金额','备 注','货到付款物流编号']
	}

	export default {
		data() {
			return {
				pagecurr: 1,
				pagesize: 10,
				listTotal: 0,
				waybillList: [],
				logisList: [],
				dialogVisibleWaybill: false,
				dialogType: 'new',
				waybillForm: Object.assign({}, defaultForm),
				waybillRules: {
					template_name: [
						{ required: true, message: this.$t('seller.validator.templateName'), trigger: 'blur' }
					],
					logistics: [
						{ required: true, message: this.$t('seller.validator.deliveryCompany'), trigger: 'change' }
					],
					checkitems: [
						{ required: true, message: this.$t('seller.validator.print'), trigger: 'change' }
					],
				},
				printList: this.$t('seller.logistics.printList'),
			}
		},
		created() {
			this.getWaybillList();
			this.getLogisList();
		},
		methods:{
			getWaybillList(){
				let param = {
					page_index: (this.pagecurr - 1) * this.pagesize,
					page_size: this.pagesize
				}
				getWaybillList(param).then(response => {
					this.waybillList = response.data.list;
					this.listTotal = parseInt(response.data.total);
				})
			},
			getLogisList(){
				expressCompany({uuid: this.$store.getters.uuid}).then(response => {
					this.logisList = response.data.list;
				})
			},
			handleSizeChange(val) {
				this.pagesize = val;
			},
			handleCurrentChange(val) {
				this.pagecurr = val;
				this.getWaybillList();
			},
			getLogisName(code){
				let name = '-';
				this.logisList.forEach(item=>{
					if(item.code == code){
						name = item.name;
					}
				})
				return name;
			},
			handleCloseWaybill(){
				this.$refs.waybillForm.clearValidate();
				this.dialogVisibleWaybill = false;
			},
			handleAddWaybill(){
				this.dialogType = 'new';
				this.waybillForm = Object.assign({}, defaultForm)
				this.dialogVisibleWaybill = true;
				this.$nextTick(function(){
					this.$refs.waybillForm.clearValidate();
				})
			},
			handleEditWaybill(id){
				this.dialogTitle = 'edit';
				fetchWaybill({template_id: id}).then(response => {
					this.waybillForm = response.data;
					this.dialogVisibleWaybill = true;
				})
			},
			handleSubmitWaybill(){
				this.$refs.waybillForm.validate((valid, object) => {
					if(valid){
						if(this.dialogType !== 'edit') {
							this.$delete(this.waybillForm,'template_id')
							createWaybill(this.waybillForm).then(() => {
								this.$message.success(this.$t('seller.successMsg.save'));
								this.getWaybillList();
								this.dialogVisibleWaybill = false;
							})
						} else {
							updateWaybill(this.waybillForm).then(() => {
								this.$message.success(this.$t('seller.successMsg.save'));
								this.getWaybillList();
								this.dialogVisibleWaybill = false;
							})
						}
					}else{
						return false;
					}
				})
			},
			handleSetDefalut(id){
				let param = {
					column: 'is_default',
					template_id: id,
					value: '1'
				}
				defaultWaybill(param).then(()=> {
					this.getWaybillList();
				})
			},
			handleDeleteWaybill(id){
				this.$confirm(this.$t('seller.confirm.deleteText'), this.$t('seller.confirm.title'), {
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					deleteWaybill({template_id: id}).then(() => {
						this.getWaybillList();
						this.$message.success(this.$t('seller.successMsg.delete'))
					})
				}).catch(() => {
					this.$message(this.$t('seller.cancelMsg.delete'));
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.logis-waybill {
		.add-part {
			display: flex;
			justify-content: right;
			padding: 15px 20px;
			font-size: 14px;
			border-left: 1px solid #EBEEF5;
			border-right: 1px solid #EBEEF5;
	
			span {
				cursor: pointer;
			}
		}
		.action-link {
			color: $--seller-primary;
		}
		.action-link:hover {
			text-decoration: underline;
		}
		.disabled {
			color: #333333;
			cursor: no-drop;
		}
		.mr10 {
			margin-right: 10px;
		}
	}
</style>
