<template>
	<div class="logis-address">
		<div class="add-part"><span @click="handleAddAddress"><i class="el-icon-document-add"></i> {{$t('seller.actions.addAddress')}}</span></div>
		<el-dialog
			:title="dialogType==='edit'?$t('seller.dialogTitle.editAddress'):$t('seller.dialogTitle.addAddress')"
			width="900px"
			:visible.sync="dialogVisibleAddress"
			:before-close="handleCloseAddress">
			<el-form
				ref="addressForm"
				label-width="120px"
				:model="addressForm"
				:rules="addressRules"
				size="small">
				<el-form-item>{{$t('seller.logistics.addressTips1')}}</el-form-item>
				<el-form-item :label="$t('seller.formItem.contacts')" prop="shipaddr_name">
					<el-input v-model="addressForm.shipaddr_name" maxlength="20" class="w240" :placeholder="$t('seller.placeholder.default')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.location')" prop="region_id" ref="region_id_item">
					<ul class="region-list">
						<li v-for="(item,index) in regionList" :key="index">
							<el-select v-model="regionIds[index]" clearable @change="handleRegionChange($event,index)">
								<el-option v-for="region in item" :key="region.region_id" :label="region.region_name"
									:value="region.region_id"></el-option>
							</el-select>
						</li>
					</ul>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.street')" prop="address">
					<el-input type="textarea" v-model="addressForm.address" :placeholder="$t('seller.placeholder.address')" style="width: 80%;" maxlength="60" show-word-limit></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.postCode')" prop="zipcode">
					<el-input v-model="addressForm.zipcode" maxlength="20" class="w240"></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.telephone')" prop="phone_tel">
					<el-input v-model="fixedPhone[0]" minlength="3" maxlength="6" class="w100" :placeholder="$t('seller.placeholder.areaCode')" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					<span> - </span>
					<el-input v-model="fixedPhone[1]" minlength="5" maxlength="10" class="w240" :placeholder="$t('seller.placeholder.phone')" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					<span> {{$t('seller.logistics.telephone')}}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.mobileNumber')" prop="phone_mob">
					<el-input v-model="addressForm.phone_mob" class="w240" maxlength="11" :placeholder="$t('seller.placeholder.mobileNumber')" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.companyName')">
					<el-input v-model="addressForm.company_name" class="w240" :placeholder="$t('seller.placeholder.companyName')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.remark')">
					<el-input type="textarea" style="width: 80%;" :placeholder="$t('seller.placeholder.remark')" v-model="addressForm.remark"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleSubmitAddress">{{$t('seller.actions.saveSet')}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-table
			:data="addressList"
			border
			style="width: 100%;">
			<el-table-column :label="$t('seller.tableHead.shippingAddess')" width="80">
				<template slot-scope="scope">
					<el-radio v-model="deliverAddress" :label="scope.row.shipaddr_id" @change="handleDefaultChange($event,'is_deliver_addr')">{{$t('seller.tag.default')}}</el-radio>
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.returnAddress')" width="80">
				<template slot-scope="scope">
					<el-radio  v-model="returnAddress" :label="scope.row.shipaddr_id"  @change="handleDefaultChange($event,'is_receiving_addr')">{{$t('seller.tag.default')}}</el-radio>
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.contacts')" prop="shipaddr_name" width="100">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.location')" prop="region_name">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.addressInfo')" prop="address">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.postCode')" prop="zipcode" width="80">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.telephone')" prop="phone_tel" width="120">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.companyName')" prop="company_name">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.phone')" prop="phone_mob" width="120">
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.handle')" width="60">
				<template slot-scope="scope">
					<div><a class="action-link" href="javascript:void(0);" @click="handleEditAddress(scope.row.shipaddr_id)">{{$t('seller.actions.edit')}}</a></div>
					<div v-if="!(scope.row.is_deliver_addr==1 || scope.row.is_receiving_addr==1)"><a class="action-link" href="javascript:void(0);" @click="handleDeleteAddress(scope.row.shipaddr_id)">{{$t('seller.actions.delete')}}</a></div>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align: center;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next" :prev-text="$t('seller.pagination.prevText')"
				:next-text="$t('seller.pagination.nextText')" :total="listTotal">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { fetchAddressList, fetchAddress, createAddress, updateAddress, deleteAddress, defaultAddress } from '@/api/seller/logistics';
	
	const defaultForm = {
		shipaddr_name: '',
		region_id: 0,
		region_name: '',
		address: '',
		zipcode: '',
		phone_tel: '',
		phone_mob: '',
		company_name: '',
		remark: '',
	}
	
	export default {
		data() {
			var self = this;
			var validateRegion = (rule,value,callback)=>{
				if(self.regionList.length !== self.regionIds.length && self.regionIds[0]==45056){
					callback(new Error(self.$t('seller.validator.domesticLocation')));
				}else if(parseInt(value)>0){
					callback();
				}else{
					callback(new Error(self.$t('seller.validator.location')));
				}
			}
			var validateTel = (rule,value,callback)=>{
				if(self.fixedPhone[0] || self.fixedPhone[1]){
					self.$refs.addressForm.validateField('phone_mob');
					let leng1 = self.fixedPhone[0]?self.fixedPhone[0].length:0;
					let leng2 = self.fixedPhone[1]?self.fixedPhone[1].length:0;
					if(leng1<3 || leng1>6){
						callback(new Error(self.$t('seller.validator.areaCodeRange')));
					}else if(leng2<5 || leng2>10){
						callback(new Error(self.$t('seller.validator.phoneRange')));
					}else{
						callback()
					}
				}else{
					callback()
				}
			}
			var validateMobile = (rule,value,callback)=>{
				if((self.fixedPhone[0] && self.fixedPhone[1]) || self.addressForm.phone_mob){
					if(value){
						let pattern = /^1[3-9]\d{9}$/
						if (pattern.test(value)) {
							callback()
						} else {
							callback(new Error(self.$t('seller.validator.mobileNumber')))
						}
					}else {
						callback()
					}
				}else{
					callback(new Error(self.$t('seller.validator.telephone')));
				}
			}
			return {
				pagecurr: 1,
				pagesize: 10,
				listTotal: 0,
				addressList: [],
				dialogVisibleAddress: false,
				dialogType: 'new',
				addressForm: Object.assign({}, defaultForm),
				addressRules: {
					shipaddr_name: [
						{ required: true, message: this.$t('seller.validator.contacts'), trigger: 'blur' }
					],
					region_id: [
						{ required: true, validator: validateRegion, trigger: 'blur' }
					],
					address: [
						{ required: true, message: this.$t('seller.validator.address'), trigger: 'blur' },
						{ min: 4, max: 60, message: this.$t('seller.validator.streetText'), trigger: 'blur' }
					],
					zipcode: [
						{ required: true, message: this.$t('seller.validator.postCode'), trigger: 'blur' },
						{ min: 6, message: this.$t('seller.validator.minLength6'), trigger: 'blur' }
					],
					phone_tel: [
						{ validator: validateTel, trigger: 'blur' },
					],
					phone_mob: [
						{ validator: validateMobile, trigger: 'blur' },
					]
				},
				regionList: [],
				regionIds: [],
				fixedPhone: [],
				deliverAddress: '',
				returnAddress: '',
			}
		},
		created() {
			this.getAddressList();
			this.getRegionList();
		},
		methods: {
			getAddressList(){
				let param = {
					page_index: (this.pagecurr - 1) * this.pagesize,
					page_size: this.pagesize
				}
				fetchAddressList(param).then(response => {
					const { data } = response;
					this.addressList = data.list;
					this.listTotal = parseInt(data.total);
					data.list.forEach(item=>{
						if(parseInt(item.is_deliver_addr)===1){
							this.deliverAddress = item.shipaddr_id;
						}
						if(parseInt(item.is_receiving_addr)===1){
							this.returnAddress = item.shipaddr_id;
						}
					})
				})
			},
			getRegionList(pid = 0, index = -1){
				fetchRegion({parent_id: pid}).then(response => {
					this.regionList.splice(index);
					if (response.data.list && response.data.list.length > 0) {
						this.regionList.push(response.data.list);
					}
				})
			},
			handleSizeChange(val) {
				this.pagesize = val;
			},
			handleCurrentChange(val) {
				this.pagecurr = val;
				this.getAddressList();
			},
			handleAddAddress(){
				this.dialogType = 'new'
				this.regionIds = [];
				this.fixedPhone = [];
				this.regionList.splice(1);
				this.addressForm = Object.assign({}, defaultForm);
				this.$nextTick(() => {
					this.$refs.addressForm.clearValidate()
				})
				this.dialogVisibleAddress = true;
			},
			handleEditAddress(id){
				this.dialogType = 'edit'
				fetchAddress({shipaddr_id: id}).then(response => {
					this.addressForm = response.data;
					this.fixedPhone = response.data.phone_tel.split('-');
					let promiseArr = [];
					let regionIds = [];
					this.regionList.splice(1);
					for(let i=1;i<5;i++){
						if(response.data['region_id' + i] > 0){
							regionIds.push(response.data['region_id' + i]);
							promiseArr.push(this.initRegionList(response.data['region_id' + i]));
						}
					}
					Promise.all(promiseArr).then(object=>{
						object.forEach(item=>{
							if(item.length>0){
								this.regionList.push(item);
							}
						})
					})
					this.regionIds = regionIds;
					this.dialogVisibleAddress = true;
				})
			},
			initRegionList(pid=0){
				return new Promise((resolve,reject)=>{
					fetchRegion({parent_id: pid}).then(response => {
						resolve(response.data.list)
					}).catch(error => {
						reject(error)
					})
				})
			},
			handleDeleteAddress(id){
				this.$confirm(this.$t('seller.confirm.deleteAddress'), this.$t('seller.confirm.title'), {
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					deleteAddress({shipaddr_id: id}).then(response => {
						this.getAddressList();
						this.$message.success(this.$t('seller.successMsg.delete'))
					})
				}).catch(() => {
					this.$message(this.$t('seller.cancelMsg.delete'));
				})
			},
			handleCloseAddress(){
				this.regionIds = [];
				this.fixedPhone = [];
				this.regionList.splice(1);
				this.$refs.addressForm.clearValidate();
				this.dialogVisibleAddress = false;
			},
			handleRegionChange(val, index) {
				if(val){
					this.regionIds.splice(index + 1);
					this.getRegionList(val, index + 1);
				}else {
					this.regionIds.splice(index);
					this.regionList.splice(index+1);
				}
				this.$nextTick(function(){
					this.addressForm.region_id = this.regionIds[this.regionIds.length-1];
					this.$refs.addressForm.validateField('region_id');
				})
			},
			handleSubmitAddress(){
				let that = this;
				let loading = this.$loading();
				this.addressForm.phone_tel = this.fixedPhone.join('-');
				this.addressForm.region_id = this.regionIds[this.regionIds.length-1];
				let regionName = '';
				for(let i = 0;i<this.regionIds.length;i++){
					this.regionList[i].forEach(item=>{
						if(item.region_id == this.regionIds[i]){
							regionName+=item.region_name;
						}
					})
				}
				this.addressForm.region_name = regionName;
				this.$refs.addressForm.validate((valid, object) => {
					if(valid){
						if(this.dialogType !== 'edit') {
							this.$delete(this.addressForm,'shipaddr_id')
							createAddress(this.addressForm).then(() => {
								this.$message.success(this.$t('seller.successMsg.save'));
								this.getAddressList();
								this.dialogVisibleAddress = false;
								loading.close();
							}).catch(() => {
								loading.close();
							})
						} else {
							updateAddress(this.addressForm).then(() => {
								this.$message.success(this.$t('seller.successMsg.save'));
								this.getAddressList();
								this.dialogVisibleAddress = false;
								loading.close();
							}).catch(() => {
								loading.close();
							})
						}
					}else{
						loading.close();
						return false;
					}
				})
			},
			handleDefaultChange(id,column){
				let param = {
					column: column,
					shipaddr_id: id,
					value: 1
				}
				defaultAddress(param).then(response => {
					this.getAddressList();
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.logis-address {
		.add-part {
			display: flex;
			justify-content: right;
			padding: 15px 20px;
			font-size: 14px;
			border-left: 1px solid #EBEEF5;
			border-right: 1px solid #EBEEF5;
	
			span {
				cursor: pointer;
			}
		}
		::v-deep .el-form {
			.w100 {
				width: 100px;
			}
			.w240 {
				width: 240px;
			}
			.el-textarea {
				.el-input__count {
					line-height: 1em;
				}
			}
		}
		.region-list {
			display: flex;
			li {
				flex-shrink: 0;
				width: 160px;
				margin-right: 10px;
			}
			li:last-child {
				margin-right: 0;
			}
		}
		.action-link {
			color: $--seller-primary;
		}
		.action-link:hover {
			text-decoration: underline;
		}
	}
</style>
